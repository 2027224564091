<template>
	<pui-modal-dialog
		v-if="dialogModel"
		:widthDialog="1200"
		:heightDialog="800"
		:titleText="$t('panels.aqi.information')"
		messageText
		:okText="$t('common.close')"
		dialogName="detailNotificationDialogDetail"
		ref="detailNotificationDialogDetail"
	>
		<template slot="message">
			<div>
				<v-tabs v-model="tab">
					<v-tab>{{ $t('aqi.aqiValues') }} </v-tab>
					<v-tab> {{ $t('aqi.aqiDistribution') }} </v-tab>
					<v-tab> {{ $t('aqi.aqibyPollutants') }} </v-tab>
					<v-tab> {{ $t('aqi.aqiDistributionByStation') }} </v-tab>
					<v-tab> {{ $t('aqi.pollutantsConcentration') }} </v-tab>
				</v-tabs>
				<v-tabs-items v-model="tab">
					<v-tab-item>
						<div style="padding: 14px">
							<pui-datatable
								:modelName="model"
								:modalDialog="true"
								:showCreateBtn="false"
								:showDeleteBtn="false"
								:pageLength="11"
								:lengthMenu="[11]"
								:modelColumnDefs="columnDefs"
								:externalFilter="externalFilter"
							>
							</pui-datatable>
						</div>
					</v-tab-item>
					<v-tab-item>
						<!--  echarts component -->
						<Heatmap />
					</v-tab-item>
					<v-tab-item>
						<!--  echarts component -->
						<HeatmapPollutants />
					</v-tab-item>
					<v-tab-item>
						<!--  echarts component -->
						<AQIChart />
					</v-tab-item>
					<v-tab-item>
						<BarChart />
					</v-tab-item>
				</v-tabs-items>
			</div>
		</template>
	</pui-modal-dialog>
</template>

<script>
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixin';

import Heatmap from '@/components/echart/Heatmap';
import HeatmapPollutants from '@/components/echart/Heatmap_pollutants';
import AQIChart from '@/components/echart/AQIChart';
import BarChart from '@/components/echart/BarChart';

import { isSuperAdmin } from '@/api/common';

export default {
	mixins: [PuiGridDetailMixin],
	name: 'pmaqivalues',
	components: {
		Heatmap,
		HeatmapPollutants,
		AQIChart,
		BarChart
	},
	props: {
		dialog: {
			default: false
		}
	},
	data() {
		return {
			model: 'pmaqivalues',
			dialogModel: false,
			tab: null,
			columnDefs: {
				disabled: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				},
				date: {
					render: (data) => {
						return data.substr(0, 10);
					}
				},
				o3conc: {
					/*render: (data) => {
						return Number(data.toFixed(2));
					}*/
					// eslint-disable-next-line no-unused-vars
					createdCell: (td, cellData, rowData) => {
						if (this.inRange(cellData, 0, 50)) {
							return (td.innerHTML = `<label style="color:#38A2CE">${Number(cellData.toFixed(2))} µg/m3</label>`);
						} else if (this.inRange(cellData, 50, 100)) {
							return (td.innerHTML = `<label style="color:#32A15E">${Number(cellData.toFixed(2))} µg/m3</label>`);
						} else if (this.inRange(cellData, 100, 130)) {
							return (td.innerHTML = `<label style="color:#F1E549">${Number(cellData.toFixed(2))} µg/m3</label>`);
						} else if (this.inRange(cellData, 130, 240)) {
							return (td.innerHTML = `<label style="color:#C83441">${Number(cellData.toFixed(2))} µg/m3</label>`);
						} else if (this.inRange(cellData, 240, 380)) {
							return (td.innerHTML = `<label style="color:#6E161D">${Number(cellData.toFixed(2))} µg/m3</label>`);
						} else if (this.inRange(cellData, 380, 800)) {
							return (td.innerHTML = `<label style="color:#A25BA4">${Number(cellData.toFixed(2))} µg/m3</label>`);
						}
					}
				},
				ozonevalue: {
					// eslint-disable-next-line no-unused-vars
					createdCell: (td, cellData, rowData) => {
						if (cellData == 1) {
							return (td.innerHTML = `<label style="color:#38A2CE">${cellData}</label>`);
						} else if (cellData == 2) {
							return (td.innerHTML = `<label style="color:#32A15E">${cellData}</label>`);
						} else if (cellData == 3) {
							return (td.innerHTML = `<label style="color:#F1E549">${cellData}</label>`);
						} else if (cellData == 4) {
							return (td.innerHTML = `<label style="color:#C83441">${cellData}</label>`);
						} else if (cellData == 5) {
							return (td.innerHTML = `<label style="color:#6E161D">${cellData}</label>`);
						} else if (cellData == 6) {
							return (td.innerHTML = `<label style="color:#A25BA4">${cellData}</label>`);
						}
					}
				},
				no2conc: {
					// eslint-disable-next-line no-unused-vars
					createdCell: (td, cellData, rowData) => {
						if (this.inRange(cellData, 0, 40)) {
							return (td.innerHTML = `<label style="color:#38A2CE">${Number(cellData.toFixed(2))} µg/m3</label>`);
						} else if (this.inRange(cellData, 40, 90)) {
							return (td.innerHTML = `<label style="color:#32A15E">${Number(cellData.toFixed(2))} µg/m3</label>`);
						} else if (this.inRange(cellData, 90, 120)) {
							return (td.innerHTML = `<label style="color:#F1E549">${Number(cellData.toFixed(2))} µg/m3</label>`);
						} else if (this.inRange(cellData, 120, 230)) {
							return (td.innerHTML = `<label style="color:#C83441">${Number(cellData.toFixed(2))} µg/m3</label>`);
						} else if (this.inRange(cellData, 230, 340)) {
							return (td.innerHTML = `<label style="color:#6E161D">${Number(cellData.toFixed(2))} µg/m3</label>`);
						} else if (this.inRange(cellData, 340, 1000)) {
							return (td.innerHTML = `<label style="color:#A25BA4">${Number(cellData.toFixed(2))} µg/m3</label>`);
						}
					}
				},
				no2value: {
					// eslint-disable-next-line no-unused-vars
					createdCell: (td, cellData, rowData) => {
						if (cellData == 1) {
							return (td.innerHTML = `<label style="color:#38A2CE">${cellData}</label>`);
						} else if (cellData == 2) {
							return (td.innerHTML = `<label style="color:#32A15E">${cellData}</label>`);
						} else if (cellData == 3) {
							return (td.innerHTML = `<label style="color:#F1E549">${cellData}</label>`);
						} else if (cellData == 4) {
							return (td.innerHTML = `<label style="color:#C83441">${cellData}</label>`);
						} else if (cellData == 5) {
							return (td.innerHTML = `<label style="color:#6E161D">${cellData}</label>`);
						} else if (cellData == 6) {
							return (td.innerHTML = `<label style="color:#A25BA4">${cellData}</label>`);
						}
					}
				},
				so2conc: {
					// eslint-disable-next-line no-unused-vars
					createdCell: (td, cellData, rowData) => {
						if (this.inRange(cellData, 0, 100)) {
							return (td.innerHTML = `<label style="color:#38A2CE">${Number(cellData.toFixed(2))} µg/m3</label>`);
						} else if (this.inRange(cellData, 100, 200)) {
							return (td.innerHTML = `<label style="color:#32A15E">${Number(cellData.toFixed(2))} µg/m3</label>`);
						} else if (this.inRange(cellData, 200, 350)) {
							return (td.innerHTML = `<label style="color:#F1E549">${Number(cellData.toFixed(2))} µg/m3</label>`);
						} else if (this.inRange(cellData, 350, 500)) {
							return (td.innerHTML = `<label style="color:#C83441">${Number(cellData.toFixed(2))} µg/m3</label>`);
						} else if (this.inRange(cellData, 500, 750)) {
							return (td.innerHTML = `<label style="color:#6E161D">${Number(cellData.toFixed(2))} µg/m3</label>`);
						} else if (this.inRange(cellData, 750, 1250)) {
							return (td.innerHTML = `<label style="color:#A25BA4">${Number(cellData.toFixed(2))} µg/m3</label>`);
						}
					}
				},
				so2value: {
					// eslint-disable-next-line no-unused-vars
					createdCell: (td, cellData, rowData) => {
						if (cellData == 1) {
							return (td.innerHTML = `<label style="color:#38A2CE">${cellData}</label>`);
						} else if (cellData == 2) {
							return (td.innerHTML = `<label style="color:#32A15E">${cellData}</label>`);
						} else if (cellData == 3) {
							return (td.innerHTML = `<label style="color:#F1E549">${cellData}</label>`);
						} else if (cellData == 4) {
							return (td.innerHTML = `<label style="color:#C83441">${cellData}</label>`);
						} else if (cellData == 5) {
							return (td.innerHTML = `<label style="color:#6E161D">${cellData}</label>`);
						} else if (cellData == 6) {
							return (td.innerHTML = `<label style="color:#A25BA4">${cellData}</label>`);
						}
					}
				},
				pm2p5conc: {
					// eslint-disable-next-line no-unused-vars
					createdCell: (td, cellData, rowData) => {
						if (this.inRange(cellData, 0, 10)) {
							return (td.innerHTML = `<label style="color:#38A2CE">${Number(cellData.toFixed(2))} µg/m3</label>`);
						} else if (this.inRange(cellData, 10, 20)) {
							return (td.innerHTML = `<label style="color:#32A15E">${Number(cellData.toFixed(2))} µg/m3</label>`);
						} else if (this.inRange(cellData, 20, 25)) {
							return (td.innerHTML = `<label style="color:#F1E549">${Number(cellData.toFixed(2))} µg/m3</label>`);
						} else if (this.inRange(cellData, 25, 50)) {
							return (td.innerHTML = `<label style="color:#C83441">${Number(cellData.toFixed(2))} µg/m3</label>`);
						} else if (this.inRange(cellData, 50, 75)) {
							return (td.innerHTML = `<label style="color:#6E161D">${Number(cellData.toFixed(2))} µg/m3</label>`);
						} else if (this.inRange(cellData, 75, 800)) {
							return (td.innerHTML = `<label style="color:#A25BA4">${Number(cellData.toFixed(2))} µg/m3</label>`);
						}
					}
				},
				pm25value: {
					// eslint-disable-next-line no-unused-vars
					createdCell: (td, cellData, rowData) => {
						if (cellData == 1) {
							return (td.innerHTML = `<label style="color:#38A2CE">${cellData}</label>`);
						} else if (cellData == 2) {
							return (td.innerHTML = `<label style="color:#32A15E">${cellData}</label>`);
						} else if (cellData == 3) {
							return (td.innerHTML = `<label style="color:#F1E549">${cellData}</label>`);
						} else if (cellData == 4) {
							return (td.innerHTML = `<label style="color:#C83441">${cellData}</label>`);
						} else if (cellData == 5) {
							return (td.innerHTML = `<label style="color:#6E161D">${cellData}</label>`);
						} else if (cellData == 6) {
							return (td.innerHTML = `<label style="color:#A25BA4">${cellData}</label>`);
						}
					}
				},
				pm10conc: {
					// eslint-disable-next-line no-unused-vars
					createdCell: (td, cellData, rowData) => {
						if (this.inRange(cellData, 0, 20)) {
							return (td.innerHTML = `<label style="color:#38A2CE">${Number(cellData.toFixed(2))} µg/m3</label>`);
						} else if (this.inRange(cellData, 20, 40)) {
							return (td.innerHTML = `<label style="color:#32A15E">${Number(cellData.toFixed(2))} µg/m3</label>`);
						} else if (this.inRange(cellData, 40, 50)) {
							return (td.innerHTML = `<label style="color:#F1E549">${Number(cellData.toFixed(2))} µg/m3</label>`);
						} else if (this.inRange(cellData, 50, 100)) {
							return (td.innerHTML = `<label style="color:#C83441">${Number(cellData.toFixed(2))} µg/m3</label>`);
						} else if (this.inRange(cellData, 100, 150)) {
							return (td.innerHTML = `<label style="color:#6E161D">${Number(cellData.toFixed(2))} µg/m3</label>`);
						} else if (this.inRange(cellData, 150, 1200)) {
							return (td.innerHTML = `<label style="color:#A25BA4">${Number(cellData.toFixed(2))} µg/m3</label>`);
						}
					}
				},
				pm10value: {
					// eslint-disable-next-line no-unused-vars
					createdCell: (td, cellData, rowData) => {
						if (cellData == 1) {
							return (td.innerHTML = `<label style="color:#38A2CE">${cellData}</label>`);
						} else if (cellData == 2) {
							return (td.innerHTML = `<label style="color:#32A15E">${cellData}</label>`);
						} else if (cellData == 3) {
							return (td.innerHTML = `<label style="color:#F1E549">${cellData}</label>`);
						} else if (cellData == 4) {
							return (td.innerHTML = `<label style="color:#C83441">${cellData}</label>`);
						} else if (cellData == 5) {
							return (td.innerHTML = `<label style="color:#6E161D">${cellData}</label>`);
						} else if (cellData == 6) {
							return (td.innerHTML = `<label style="color:#A25BA4">${cellData}</label>`);
						}
					}
				},
				pmaqidescriptionid: {
					createdCell: (td, cellData, rowData) => {
						return (td.innerHTML = `<label class="fw-500" style="border-radius:4px;padding:2px 4px;background-color:${rowData.color + '99'};color:black">${cellData}</label>`);
					}
				},
				name: {
					createdCell: (td, cellData, rowData) => {
						return (td.innerHTML = `<label class="fw-500" style="border-radius:4px;padding:2px 4px;background-color:${rowData.color + '99'};color:black">${cellData}</label>`);
					}
				}
			}
		};
	},
	watch: {
		dialog() {
			this.dialogModel = this.dialog;
		}
	},
	created() {
		this.$puiEvents.$on('pui-modalDialog-detailNotificationDialogDetail-ok', () => {
			this.dialogModel = false;
			this.$emit('update:dialog', false);
		});
	},
	mounted() {
		// eslint-disable-next-line no-unused-vars

		if (!isSuperAdmin(this.session.profiles[0])) {
			this.externalFilter = {
				groupOp: 'or',
				rules: [{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }],
				groups: []
			};
		}
	},
	destroyed() {
		this.$puiEvents.$off('pui-modalDialog-detailNotificationDialogDetail-ok');
	},
	methods: {
		inRange(value, min, max) {
			// return true if in range, otherwise false
			return (value - min) * (value - max) <= 0;
		}
	}
};
</script>

<style scoped>
.title {
	font-size: 20px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: #414141;
	margin-top: 10px;
}
</style>
